export default {
  firebase: {
    apiKey: "AIzaSyDYMOzQCsBrwupLtTCBnuhVSXRvis_ukRQ",
    authDomain: "barber-io.firebaseapp.com",
    databaseURL: "https://barber-io.firebaseio.com",
    projectId: "barber-io",
    storageBucket: "barber-io.appspot.com",
    messagingSenderId: "1075163026873"
  },
  analytics: {
    trackingId: "UA-132351671-2"
  },
  functions: {
    app: "https://us-central1-barber-io.cloudfunctions.net/app"
  },
  bugsnag: {
    apiKey: "404ce71e5c23af87bd1980bc1895331c",
    releaseStage: "production"
  }
};
